import React from 'react';
import { Text } from 'rebass';
import { titleList } from './css/positions.css';
import CareerPosition from '../../../components/layout/Career/CareerPosition';
import { ListItemSquare } from '../../../components/layout/ListItemComponents/ListItemSquare';

const ProductDeveloper: React.FC = () => {
  return (
    <CareerPosition positionTitle="Product Developer / UX Dizajner">
      <Text mt={'30px'} mb={'10px'} css={titleList} textAlign="left">
        Znanja i veštine
      </Text>
      <ListItemSquare text={'Minimum 3-4 godina iskustva u radu sa UI dizajnom'} />
      <ListItemSquare text={'Iskustvo u radu sa interfejsima mobilnih i web aplikacija'} />
      <ListItemSquare text={'Profesionalno iskustvo u radu sa alatima za grafički dizajn poput Figme, Sketcha ili Adobe XD'} />
      <ListItemSquare text={'Orijentisanost ka korisniku i proizvodu'} />
      <ListItemSquare text={'Snalažljivost i inicijativa ka poboljšanju proizvoda i korisničkog iskustva'} />
      <Text mt={'30px'} mb={'10px'} css={titleList} textAlign="left">
        Bonus (uvažavamo dodatno)
      </Text>
      <ListItemSquare
        text={
          'Iskustvo u korišćenju tehnika poput Empathy mappinga, Card sortinga, User persona, Heurističkih evaluacija, i drugih metoda kvalitativnih istraživanja'
        }
      />
      <ListItemSquare text={'Iskustvo u radu sa Google Design Sprint metodama'} />
      <ListItemSquare text={'Poznavanje Lean UX metoda'} />
      <Text mt={'30px'} mb={'10px'} css={titleList} textAlign="left">
        Odgovornosti i zaduženja
      </Text>
      <ListItemSquare text={'Razvijanje wireframe-ova i vizuelnih prototipa korisničkih interfejsa'} />
      <ListItemSquare text={'Samostalan rad u razvijanju konačnih grafičkih rešenja'} />
      <ListItemSquare text={'Definisanje i vizuelno predstavljanje kretanja korisnika kroz interfejs (user flow)'} />
      <ListItemSquare text={'Definisanje modela interakcija i evaluacije uspešnosti tih modela (testiranje i istraživanje)'} />
      <ListItemSquare text={'Osmišljavanje i predlaganje koncepata novih elemenata interfejsa i proizvoda'} />
      <ListItemSquare text={'Konstantna komunikacija i rad sa ostalim članovima Marketing i Growth tima'} />
      <ListItemSquare
        text={'Učestvovanje ili iniciranje sprovođenja ostalih istraživanja koja se tiču korisnika, korisničkih navika, tržišta'}
      />
      <ListItemSquare text={'Saradnja sa ostalim grafičkim i interfejs dizajnerima u timu pri kreiranju konačnih grafičkih rešenja'} />
      <ListItemSquare text={'Pružanje inicijative, kao i usmeravanje tima ka poboljšanju proizvoda i korisničkog iskustva'} />
      <ListItemSquare text={'Za svoj rad odgovara Direktoru i Direktoru sektora Korporativnog razvoja'} />
    </CareerPosition>
  );
};

export default ProductDeveloper;
